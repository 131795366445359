import { initMessageConfig } from "../../data/default/projectConfig.data";
import ColorsMap from "../SystemMaps/ColorsMap";
import MakersMap from "../SystemMaps/MakersMap";
import StreetsMap from "../SystemMaps/StreetsMap";
import VehicleGroupMap from "../SystemMaps/VehicleGroupMap";
import ZonesMap from "../SystemMaps/ZonesMap";
import PaymentConfig from "./PaymentConfig";

class ProjectDetails {

    constructor(json = {projectId: -1, userName: '', taveiDayarSiteConfig: {}, listUserName: [], hasAdditionalAddress: false, cityLogUrl: '', vehicleGroups: []}){
        this.projectId = -1; 

        
        this.userName = '';
        if(json['userName'] !== undefined) this.userName = json['userName'];

        if(json['projectId'] !== undefined) this.projectId = json['projectId'];
                
        this.streets = new StreetsMap(json.streets);
        this.colors = new ColorsMap(json.colors);
        this.types= new MakersMap(json.types);
        this.zones = new ZonesMap(json.zones);
        this.vehicleGroups = new VehicleGroupMap(json.vehicleGroups);
        this.listUserName = json.listUserName ? json.listUserName: [];
        this.cityLogUrl = json.cityLogUrl ? json.cityLogUrl: '';
        const hasPayment = json.taveiDayarSiteConfig.tranzilaSupplier === "true" ? true : false;

        this.paymentConfig = new PaymentConfig(
            json.hasAdditionalAddress,
            json.taveiDayarSiteConfig.colorAndType,
            hasPayment,
            json.taveiDayarSiteConfig.payment,
            json.taveiDayarSiteConfig.gilHaZahavOption,
            json.taveiDayarSiteConfig.gilHaZahavPayment,
            json.taveiDayarSiteConfig.gilHaZahavValidityPeriod,
            json.taveiDayarSiteConfig.gilHaZahavAge,
            json.taveiDayarSiteConfig.studentOption,
            json.taveiDayarSiteConfig.studentPayment,
            json.taveiDayarSiteConfig.studentValidityPeriod,
            json.taveiDayarSiteConfig.hesSendSmsOption,
            json.taveiDayarSiteConfig.smsMessage,
            json.taveiDayarSiteConfig.validityPeriod,
            json.taveiDayarSiteConfig.municipalEmployeeOption,
            json.taveiDayarSiteConfig.municipalEmployeePayment,
            json.taveiDayarSiteConfig.municipalEmployeeValidityPeriod,
        );
        
        this.projectName = json.projectName;

        this.messageConfig = json.messageConfig ? json.messageConfig : initMessageConfig;
    }
}

export default ProjectDetails;