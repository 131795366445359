import React from 'react';
import InputGroup from '../../../../Components/Forms/InputGroup';
import SimpleCard from '../../../../Components/UI/Card/SimpleCard';
import PaymentMethod from '../../../../models/PaymentMethod';
import SelectYearPayment from '../../../../Components/Forms/select/payment/SelectYearPayment';
import SelectMonthPayment from '../../../../Components/Forms/select/payment/SelectMonthPayment';
import RadioBtnsContainer from '../../../../Components/Forms/radio/RadioBtnsContainer';
import RadioOutline from '../../../../Components/Forms/radio/RadioOutline';
import { useAddPermitPageContext } from '../../../../contexts/permit/AddPermitPageContext';
import FormSection from '../../../../Components/UI/formSection/FormSection';

const PaymentForm = ({ }) => {

    const { errors, values, touched, setFieldValue, handleChange, handleBlur } = useAddPermitPageContext();
    const permitType = values.permitType;
    const moneyAmount = values.amount;
    const title = <>תשלום <strong>(₪{moneyAmount})</strong></>
    const disabledPaymentOption = moneyAmount <= 0 ? true : false;

    return (
        <FormSection title={title}>

            <div className="row ">
                <div className="col">
                    <RadioBtnsContainer label={'צורת תשלום'}>
                        <RadioOutline
                            isChecked={values.paymentMethod === PaymentMethod.CASH ? true : false}
                            label='תשלום מזומן'
                            value={PaymentMethod.CASH}
                            onChange={() => { }}
                            onClick={handleClick}
                            id="paymentMethodCash"
                            name='paymentMethod'
                            disabled={disabledPaymentOption}
                        />
                        <RadioOutline
                            isChecked={values.paymentMethod === PaymentMethod.CREDIT_CARD ? true : false}
                            label='תשלום אשראי'
                            value={PaymentMethod.CREDIT_CARD}
                            onChange={() => { }}
                            onClick={handleClick}
                            id="paymentMethodCredit"
                            name='paymentMethod'
                            disabled={disabledPaymentOption}
                        />
                    </RadioBtnsContainer>
                </div>
            </div>

            {values.paymentMethod === PaymentMethod.CREDIT_CARD ? (
                <React.Fragment>
                    <div className="row">
                        <div className="col-lg-6 col-xl-3">
                            <InputGroup
                                name={'cardHolderId'}
                                label={'תעודת זהות של בעל הכרטיס'}
                                value={values.cardHolderId}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorMsg={(errors.cardHolderId && touched.cardHolderId) ? errors.cardHolderId : undefined}
                            />
                        </div>

                        <div className="col-lg-6 col-xl-3">
                            <InputGroup
                                name={'cardNumber'}
                                label={'מספר כרסיט אשראי'}
                                value={values.cardNumber}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorMsg={(errors.cardNumber && touched.cardNumber) ? errors.cardNumber : undefined}
                            />
                        </div>

                        <div className="col-lg-3 col-xl-2">
                            <SelectYearPayment
                                label={'שנה'}
                                isRequired={true}
                                name={'cardExpireDateYY'}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorMsg={(errors.cardExpireDateYY && touched.cardExpireDateYY) ? errors.cardExpireDateYY : undefined}
                            />
                        </div>
                        <div className="col lg-3 col-xl-2">
                            <SelectMonthPayment
                                label={'חודש'}
                                isRequired={true}
                                name={'cardExpireDateMM'}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorMsg={(errors.cardExpireDateMM && touched.cardExpireDateMM) ? errors.cardExpireDateMM : undefined}
                            />
                        </div>
                        <div className="col-lg-6 col-xl-2">
                            <InputGroup
                                name={'cardAddedNumber'}
                                label={'CVV'}
                                value={values.cardAddedNumber}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorMsg={(errors.cardAddedNumber && touched.cardAddedNumber) ? errors.cardAddedNumber : undefined}
                            />
                        </div>
                        <div className="col-lg-6 col-xl-3">
                            <InputGroup
                                name={'cardHolderName'}
                                label={'שם על גב הכרטיס'}
                                value={values.cardHolderName}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorMsg={(errors.cardHolderName && touched.cardHolderName) ? errors.cardHolderName : undefined}
                            />
                        </div>

                    </div>
                </React.Fragment>
            ) : ''}
        </FormSection>
    );

    function handleClick(e) {
        setFieldValue('paymentMethod', e.currentTarget.value);
    }
}

export default PaymentForm