import moment from 'moment';
import { convertCodeCreatedByToText, convertCodeStatusRequestToText } from '../convertTypeCodeToText';
import { decodeNCRStringIfNeeded } from '../../language';
import PaymentMethod from '../../../types/PaymentMethod';
const DATE_FORMAT_STR = "DD/MM/YYYY, HH:mm:ss";

export function convRequestRowRawDataFromServer(requestData) {

    const rowRequestTav = requestData.request;
    const comparingFilesResult = requestData.comparingFilesResult;
    let tavRequest = {};
    const creationDateNum = parseInt(rowRequestTav.updateDate, 10);
    const creationDate = moment(new Date(creationDateNum)).format(DATE_FORMAT_STR);

    const sentToSmsDateNum = parseInt(rowRequestTav.sentToSmsDate, 10);
    let sentToSmsDate = 'לא נשלח';
    let statusRequest = '';
    let createdBy = '';
    let statusRequestCode = parseInt(rowRequestTav.status);
    let createdByCode = rowRequestTav.createdBy;

    if(sentToSmsDateNum > 0) sentToSmsDate = moment(new Date(sentToSmsDateNum)).format(DATE_FORMAT_STR);

    createdBy = convertCodeCreatedByToText(createdByCode);
    statusRequest = convertCodeStatusRequestToText(statusRequestCode);
    let paymentMethod = PaymentMethod.NONE;

    if(rowRequestTav.hasPaidCash) {
        paymentMethod = PaymentMethod.CASH;
    } else if(rowRequestTav.hasPaidCredit) {
        paymentMethod = PaymentMethod.CARD;
    }
    
    tavRequest['key'] = rowRequestTav.id;
    tavRequest['lName'] = decodeNCRStringIfNeeded(rowRequestTav.familyName);
    tavRequest['fName'] = decodeNCRStringIfNeeded(rowRequestTav.privateName);
    tavRequest['updateDate'] = creationDate;
    tavRequest['id'] = rowRequestTav['id'];
    tavRequest['secreteryMessage'] = decodeNCRStringIfNeeded(rowRequestTav['secreteryMessage']);
    tavRequest['personId'] = rowRequestTav['personId'];
    tavRequest['createdBy'] = createdBy;
    tavRequest['createdByCode'] = createdByCode;
    tavRequest['carNum'] = rowRequestTav['carNum'];
    tavRequest['serialId'] = rowRequestTav['serialId'];
    tavRequest['status'] = statusRequest;
    tavRequest['statusRequestCode'] = statusRequestCode;
    tavRequest['permitNumber'] = rowRequestTav['tavDayar'];
    tavRequest['reportNum'] = rowRequestTav['reportNum'];
    tavRequest['mobile'] = rowRequestTav['mobile'];
    tavRequest['sentToSmsDate'] = sentToSmsDate;
    tavRequest['sentToSmsDateNum'] = sentToSmsDateNum;
    tavRequest['paymentMethod'] = paymentMethod;
    tavRequest['moneyAmount'] = rowRequestTav.moneyAmount;
    tavRequest['comparingFtatus'] = comparingFilesResult;
    tavRequest['vehicleGroupId'] = rowRequestTav.vehicleGroupName;
    return tavRequest;
}
