export const initLoginData = {
    projectId: 1,
    userName: '',
    password: ''
}

export const initDammyLoginData = {
    projectId: 27,
    userName: 'plola27',
    password: 'lola27'
}