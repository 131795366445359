import { useContext } from "react";
import useAxiosPrivate from "../useAxiosPrivate";
import ResponseStatus from "../../types/ResponseStatus";

const fieldNotToSent = [
    'tavDayar',
    'reportNum',
    'insertUserId',
    'tavDayar',
    'updateDate',
    'asmachta',
    'userRegisterDate',
    'approvalDate',
    'userInsertName',
    'asmachta',
    'createdBy',
    'asmachta',
    'status',
    'projectId',
    'moneyAmount'];

export const useRequestDetails = () => {

    const axiosPrivate = useAxiosPrivate();

    async function getRequestFiles(values) {

        const data = {
            citizenIdPath: '',
            carIdPath: '',
            pathScandLeasing: '',
            personId: -1
        };

        if(values.citizenIdPath != undefined) {

            data.citizenIdPath = values.citizenIdPath.substring(values.citizenIdPath.lastIndexOf('/'));
        }

        if(values.carIdPath != undefined) {
            data.carIdPath = values.carIdPath.substring(values.carIdPath.lastIndexOf('/'));
        }

        if(values.pathScandLeasing != undefined) {

            data.pathScandLeasing = values.pathScandLeasing.substring(values.pathScandLeasing.lastIndexOf('/'));
        }

        const result = {
            status: ResponseStatus.SUCCESS,
            message: '',
            data: {
                files: []
            }
        };

        try {
        
            const response = await axiosPrivate.post('/files/request-files', data);

            result.status = response.data.status;
            result.message = response.data.message;
            result.data.files = response.data.data.files;

        } catch (error) { 

            result.status = ResponseStatus.FAILURE;
            result.message = 'בקשה לא נמצאה';
            
            if(error?.response) {
                result.message = error?.response.message;
            }

            console.log('[getPermitRequest] error: ', error);
        }

        return result;
    }

    async function getPermitRequest (requestId) {

        const result = {
            status: ResponseStatus.SUCCESS,
            message: '',
            data: {
                citizenSticker: null,
                endDate: '',
                greatestEndDateOfActivePermit: '',
                exitingPermitWithSamePId: 0,
            }
        };

        try {

            const response = await axiosPrivate.get(`/request/${requestId}`);
            const data = response.data;

            let greatestEndDateOfActivePermit = data.greatestEndDateOfActivePermit ? data.greatestEndDateOfActivePermit : '-1';

            if(greatestEndDateOfActivePermit === '' || greatestEndDateOfActivePermit === '-1') {
                greatestEndDateOfActivePermit = '';
            }

            result.status = ResponseStatus.SUCCESS;
            result.message = '';
            result.data.citizenSticker = data.request;
            result.data.endDate = data.estimateEndDate;
            result.data.greatestEndDateOfActivePermit = greatestEndDateOfActivePermit;
            result.data.exitingPermitWithSamePId = data.countActivePermitWithSamePersinId ? data.countActivePermitWithSamePersinId : 0;
            
        } catch (error) { 

            result.status = ResponseStatus.FAILURE;
            result.message = 'בקשה לא נמצאה';
            
            if(error?.response){
                result.message = error?.response.message;
            }

            console.log('[getPermitRequest] error: ', error);
        }

        return result;
    }

    async function approveRequest(values) {

        const form = new FormData();
        const result = {status : '', message: 'success'};
        
        for (let key in values) {

            if (fieldNotToSent.indexOf(key) > -1) continue;

            let value = values[key]

            if (value === null || value === undefined) continue;

            if(typeof value === 'object' && !Array.isArray(value)) {
                
                value = JSON.stringify(value);
            } 

            form.append(key, value);
        }

        try {

            const response = await axiosPrivate
                .post('/request/approving-request', form);

            const data = response.data;

            result.status = data.status;
            result.message = data.message
        } catch (error) {

            console.log('error ', error);
            result.status = 'failure';
            result.message = 'בעיה כללית בשרת';

            if (error.message === 'record is valid')
                result.message = 'קיימת כבר בקשה במערכת עם מספר הרכב ותעודת הזהות';
            else if (error.message === 'permit is valid')
                result.message = 'קיים כבר תו בתוקף במערכת עם מספר הרכב ותעודת הזהות'; 
        }

        return result;
    }

    async function saveRequest (values) {
     
        const form = new FormData();

        for (let key in values) {

            if (fieldNotToSent.indexOf(key) > -1) continue;

            let value = values[key]

            if (value === null || value === undefined) continue;

            if(typeof value === 'object' && !Array.isArray(value)) {
                
                value = JSON.stringify(value);
            } 
            
            form.append(key, value);
        }

        let status = 'success';
        let message = 'success';

        try {

            const response = await axiosPrivate
                .post('/request/updateRequest', form);

            const data = response.data;

            status = data.status;
            message = data.message
        } catch (error) {

            console.log('error ' + error);

            message = 'בעיה כללית בשרת';

            if (error.message === 'record is valid')
                message = 'קיימת כבר בקשה במערכת עם מספר הרכב ותעודת הזהות';
            else if (error.message === 'permit is valid')
                message = 'קיים כבר תו בתוקף במערכת עם מספר הרכב ותעודת הזהות';

            status = 'failure';
        }

        return { status: status, message: message };
    }

    
    return { saveRequest, approveRequest, getPermitRequest, getRequestFiles };
}