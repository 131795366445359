import React from 'react';
import { CheckBoxColors } from '../../../types/checkBoxColors';
import style from '../forms.module.css';

const Radio = ({ value, id, name, onClick, onBlur, label, isChecked, disabled, color }) => {

    const radioBoxInputClasses = `${style.radioBoxInput} ${getRadioInputClass(color)}`;

    return (
        <label htmlFor={id} className={style.radioBoxLabel}>
            <input
                disabled={disabled}
                checked={isChecked}
                value={value}
                onBlur={onBlur}
                id={id}
                name={name}
                className={radioBoxInputClasses}
                type='radio'
                autoComplete="off"
                onClick={(e) => onClick(e)}
            />
            {label}
        </label>
    );

    function getRadioInputClass(color/*?: CheckBoxColors*/) {

        if(!color) {
            return style.radioBoxInputPrimary;
        }
    
        if(color === CheckBoxColors.SECONDARY) {
            return style.radioBoxInputSecondary;
        } else if(color === CheckBoxColors.DANGER) {
            return style.radioBoxInputDanger;
        }else if(color === CheckBoxColors.INFO) {
            return style.radioBoxInputInfo;
        }else if(color === CheckBoxColors.LIGHT) {
            return style.radioBoxInputLight;
        }else if(color === CheckBoxColors.PRIMARY) {
            return style.radioBoxInputPrimary;
        }else if(color === CheckBoxColors.SUCCESS) {
            return style.radioBoxInputSuccess;
        }else if(color === CheckBoxColors.WARNING) {
            return style.radioBoxInputWarning;
        }
    
    }
}

Radio.defaultProps = {
    label: '',
    value: '',
    isRequired: false,
    disabled: false,
    errorMsg: '',
    onBlur: () => { },
    onClick: () => { },
    color: CheckBoxColors.PRIMARY
}

export default Radio;