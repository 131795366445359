import OwnershipCarType from "../../types/OwnershipCarType";
import PermitType from "../../types/PermitType";

export const initRequestPermitData = {
    projectId: -1,
    requestId: -1,
    ownershipCarType: OwnershipCarType.PRIVATE,
    carNumber: '',
    vColor: -1,
    vType: -1,
    vehicleGroupName: -1,
    permitType: PermitType.REGULAR,
    familyName: '',
    privateName: '',
    personId: '',
    phone: '',
    mobile: '',
    house: '',
    appartment: '',
    street: '',
    email: '',
    additionalAddress: '',
    mailCity: '',
    mailHouse: '',
    mailMikod: '',
    mailStreet: '',
    mailAppartment: '',
    hasPaidCash: false,
    hasPaidCredit: false,
    asmachta: '',
    moneyAmount: 0,
    reportNum: -1,
    insertUserId: -1,
    tavDayar: -1,
    updateDate: -1,
    userRegisterDate: -1,
    approvalDate: -1,
    hardCopyPersonId: 0,
    hardCopyLicense: 0,
    hardCopyLeasing: 0,
    approveUserId: -1,
    secreteryMessage: '',
    userInsertName: '',
    createdBy: '',
    status: -1,
    extraFile1: '',
    citizenIdPath: '',
    carIdPath: '',
    pathScandLeasing: '',
    extraFilesPaths: '',
    extra4Map: {},
}

export const initComparisonRequestDataAndFiles = {
    "carNum": '',
    "fName": '',
    "lName": '',
    "personId": '',
    "lNameAppearsInPersonId": true,
    "personIdNumAppearsInCarId": false,
    "personIdNumAppearsInPersonIdCard": false,
    "lNameAppearsInCarId": true,
    "carNumAppearsInCarId": true,
    "fNameAppearsInPersonId": false,
    "fNameAppearsInCarId": false,
    "projectNInAdditionalPerIdFile": false,
}