class VehicleGroupMap {

    constructor(vehicleGroups = []){
        let arr = [];

        if(Array.isArray(vehicleGroups)) arr = vehicleGroups;
        
        this.vehicleMap = arr;
    }

    getAllVehicleGroups(){

        return this.vehicleMap;
    }
}

export default VehicleGroupMap;