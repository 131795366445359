import React from 'react';
import SelectWithSearch from './SelectWithSearch';
import { decodeNCRStringIfNeeded } from '../../../Helpers/language';
import { useProjectDetailsContext } from '../../../contexts/ProjectDetailsContext';


const SelectVehicleGroup = ({ name, handleBlur, handleChange, errorMsg, value }) => {

    const {projectDetails} = useProjectDetailsContext();
    const vehicleGroupList = projectDetails.vehicleGroups.getAllVehicleGroups();

    let vehicleGroups = vehicleGroupList.map(vehicleGroup => {

        return { label: decodeNCRStringIfNeeded(vehicleGroup.vehicleGroupName), value: vehicleGroup.id }
    });

    vehicleGroups = [{ value: -1, label: 'אחר' }, ...vehicleGroups];

    const valueStr = vehicleGroups.findLast(option => option.value == value);

    return (
        <SelectWithSearch
            errorMsg={errorMsg}
            name={name}
            label={'סוג רכב'}
            value={valueStr}
            isRequired={true}
            selects={vehicleGroups}
            handleBlur={handleBlur}
            handleChange={handleChange} />
    )
}

export default SelectVehicleGroup;