import React, { useEffect, useState } from 'react';
import { useRequestManagmentPageContext } from '../../../../contexts/request/RequestDetailsPageContext';
import InputGroup from '../../../../Components/Forms/InputGroup';
import SelectCarColor from '../../../../Components/Forms/select/SelectCarColor';
import SelectCarMaker from '../../../../Components/Forms/select/SelectCarMaker';
import OwnershipCarType from '../../../../types/OwnershipCarType';
import RadioBtnsContainer from '../../../../Components/Forms/radio/RadioBtnsContainer';
import RadioOutline from '../../../../Components/Forms/radio/RadioOutline';
import FormSection from '../../../../Components/UI/formSection/FormSection';
import SelectVehicleGroup from '../../../../Components/Forms/select/SelectVehicleGroup';

const VehicleDetails = () => {

    const { handleChange, errors, values, touched, handleBlur, setFieldValue, setFieldTouched } = useRequestManagmentPageContext();
    const [radioBtnState, setChecked] = useState([
        values.ownershipCarType === OwnershipCarType.PRIVATE ? true : false, 
        values.ownershipCarType === OwnershipCarType.COMPANY ? true : false
    ]);

    useEffect(() => {

        setChecked([values.ownershipCarType === OwnershipCarType.PRIVATE ? true : false, values.ownershipCarType === OwnershipCarType.COMPANY ? true : false]);
    }, [values.ownershipCarType]);

    return (
        <FormSection title={"פרטי הרכב"}>
            <div className="row">
                <div className='col-md-6 col-lg-2'>
                    <InputGroup
                        disabled={true}
                        name={'carNumber'}
                        label={'מספר רכב'}
                        inputType={'number'}
                        value={values.carNumber}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errorMsg={(errors.carNumber && touched.carNumber) ? errors.carNumber : undefined}
                    />
                </div>

                <div className="col-md-6 col-lg-3">
                    <SelectCarMaker
                        name={'vType'}
                        value={values.vType}
                        handleChange={(option) => setFieldValue('vType', option.value)}
                        handleBlur={e => {
                            handleBlur(e);
                            setFieldTouched('vType', true);
                        }}
                        errorMsg={(errors.vType && touched.vType) ? errors.vType : undefined} />
                </div>
                <div className="col-md-6 col-lg-3">
                    <SelectCarColor
                        name={'vColor'}
                        value={values.vColor}
                        handleChange={(option) => setFieldValue('vColor', option.value)}
                        handleBlur={e => {
                            handleBlur(e);
                            setFieldTouched('vColor', true);
                        }}
                        errorMsg={(errors.vColor && touched.vColor) ? errors.vColor : undefined}
                    />
                </div>

                <div className="col-md-6 col-lg-3">
                    <SelectVehicleGroup
                        name={'vehicleGroupName'}
                        value={values.vehicleGroupName}
                        handleChange={(option) => setFieldValue('vehicleGroupName', option.value)}
                        handleBlur={e => {
                            handleBlur(e);
                            setFieldTouched('vColor', true);
                        }}
                        errorMsg={(errors.vehicleGroupName && touched.vehicleGroupName) ? errors.vehicleGroupName : undefined}
                    />
                </div>

                <div className="col-md-6 col-lg-3">
                    <RadioBtnsContainer label={'בעלות רכב'}>

                        <RadioOutline
                            isChecked={radioBtnState[0]}
                            label='רכב פרטי'
                            value={OwnershipCarType.PRIVATE}
                            onChange={() => { }}
                            onClick={handleClick}
                            id="ownershipCarTypeYes"
                            name='ownershipCarType'
                        />
                        <RadioOutline
                            isChecked={radioBtnState[1]}
                            label='רכב חברה'
                            value={OwnershipCarType.COMPANY}
                            onChange={() => { }}
                            onClick={handleClick}
                            id="ownershipCarTypeNo"
                            name='ownershipCarType'
                        />
                    </RadioBtnsContainer>
                </div>
            </div>
        </FormSection>
    );

    function handleClick(e) {
        setFieldValue('ownershipCarType', Number(e.currentTarget.value));
    }

}

export default VehicleDetails;