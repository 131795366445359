import React, { useContext } from 'react';
import Style from './Navbar.module.css';
import UiList from './UiList';
import { ProjectDetailsContext } from "../../../contexts/ProjectDetailsContext";
import UserDetailsBox from './UserDetailsBox';
import { decodeNCRStringIfNeeded } from '../../../Helpers/language';

const Navbar = () => {

    const { projectDetails } = useContext(ProjectDetailsContext);
    const navClassStyle = "navbar navbar-expand-sm navbar-light " + Style.navbar;
    const classesNavBarnd = 'navbar-brand ' + Style.brandNavbar;

    return (
        <nav className={navClassStyle} style={{backgroundColor: "#e3f2fd;"}}>
            <div className="container-fluid">
                <div className={classesNavBarnd}>
                    <img className={Style.cityLogUrl} src={projectDetails.cityLogUrl}/>
                    <div>
                        <div>ניהול תווי דייר</div>
                        <div className={Style.projectNameDiv}>{decodeNCRStringIfNeeded(projectDetails.projectName)}</div>
                    </div>
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <UiList />
                <UserDetailsBox />
            </div>
        </nav>
    );

//         <strong className={style.projectNameDiv}>{decodeNCRStringIfNeeded(projectDetails.projectName)}</strong>
}

export default Navbar;